import { ApplicationController, useIntersection  } from 'stimulus-use'

export default class extends ApplicationController {
  connect() {
    useIntersection(this, { threshold: 0.1 })
  }

  appear() {
    setTimeout(() => {
      let video = this.element
      if (this.element.tagName.toUpperCase() !== 'VIDEO') {
        video = [...this.element.children].find(e => e.tagName.toUpperCase() === 'VIDEO')
      }
      video.preload = "metadata"
    })
  }
}
